<template>
    <div class="card" v-if="collections && collections.length > 0">
        <header class="card-header">
            <p class="card-header-title">
                Project Data
            </p>
        </header>
        <div class="card-content menu">

            <div class="menu-label is-flex is-vcentered columns">
                <img
                    :src="
                        ASSETS_BASE_URL + 'datamodel/icons/SVG/coll-light.svg'
                    "
                    alt="BossDB Collection Icon"
                    style="height: 1.7em; padding-right: 10px"
                />
                <div>Collections</div>

            </div>
            <ul class="menu-list">
                <li v-for="collection in collections">
                    <a @click="collection.show = !collection.show">
                        <span v-show="collection.show">
                            <!-- Vue.js 'v-if' and Bulma <i> did not play nice together -->
                            <i class="fa fa-caret-down" key="down"></i>
                        </span>
                        <span v-show="!collection.show">
                            <i class="fa fa-caret-right" key="right"></i>
                        </span>
                        {{ collection.id }}
                    </a>

                    <div v-if="collection.show">
                        <div class="menu-label is-flex is-vcentered columns indented">
                            <img
                                :src="
                                    ASSETS_BASE_URL + 'datamodel/icons/SVG/exp-light.svg'
                                "
                                alt="BossDB Experiment Icon"
                                style="height: 1.7em; padding-right: 10px"
                            />
                            <div>Experiments</div>
                        </div>
                        <ul class="menu-list">
                            <li v-for="experiment in collection.experiments.slice(0,collection.displayRange)">
                                <ExperimentDropdown :exp="experiment" />
                            </li>
                            <template
                                v-if="collection.experiments.length > collection.displayRange + 1"
                            >
                                <li>
                                    <a @click="collection.displayRange += maxItems">...</a>
                                </li>
                                <li>
                                    <ExperimentDropdown :exp="collection.experiments.slice(-1)[0]" />
                                </li>
                            </template>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { ASSETS_BASE_URL } from "@/assets.js";
import MetadataRemote from "@/metadata.js";
import ExperimentDropdown from "./ExperimentDropdown.vue";

export default {
    name: "DataCard",
    props: {
        collection_ids: {
            type: Array,
            required: true
        }
    },

    components: {
        ExperimentDropdown,
    },

    created() {
        let metadataRemote = new MetadataRemote();
        this.collections = [];
        this.maxItems = 10;

        this.collection_ids.forEach(collection_id => {
            metadataRemote
                .getCollection(collection_id)
                .then(blob => {
                    this.collections.push({
                        id: blob.attributes.ID,
                        name: blob.attributes.ID,
                        experiments: [],
                        show: false,
                        displayRange: this.maxItems - 1
                    });

                    this.collections.forEach(collection => {
                        metadataRemote
                            .getExperimentsByCollection(collection.id)
                            .then(blob => {
                                collection.experiments.push(
                                    ...blob.map(b => {
                                        return {
                                            id: b.attributes.ID,
                                            name: b.attributes.Name,
                                            channels: [],
                                            show: false,
                                            displayRange: this.maxItems - 1
                                        };
                                    })
                                );

                                collection.experiments.forEach(experiment => {
                                    metadataRemote
                                        .getChannelsByExperiment(experiment.id)
                                        .then(blob => {
                                            experiment.channels.push(
                                                ...blob.map(b => {
                                                    return {
                                                        id: b.attributes.ID,
                                                        name: b.attributes.Name,
                                                        show: false
                                                    };
                                                })
                                            );
                                        })
                                        .catch(error => {
                                            console.error(
                                                `Unable to find meatdata for channels. ${error}`
                                            );
                                        });
                                });
                            })
                            .catch(error => {
                                console.error(
                                    `Unable to find meatdata for experiments. ${error}`
                                );
                            });
                    });
                })
                .catch(error => {
                    console.error(
                        `Unable to find meatdata for collection ${collection_id}. ${error}`
                    );
                });
        });
    },
    data() {
        return {
            ASSETS_BASE_URL,
            collections: this.collections,
            maxItems: this.maxItems
        };
    }
};
</script>

<style lang="scss">
.indented {
    margin-left: 1.2em;
    margin-top: 5px;
}
</style>
