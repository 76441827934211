<template>
    <span>
        <a @click="experiment.show = !experiment.show">
            <span v-show="experiment.show">
                <!-- Vue.js 'v-if' and Bulma <i> did not play nice together -->
                <i class="fa fa-caret-down" key="down"></i>
            </span>
            <span v-show="!experiment.show">
                <i class="fa fa-caret-right" key="right"></i>
            </span>
            {{ experiment.name }}
        </a>

        <div v-if="experiment.show">
            <div class="menu-label is-flex is-vcentered columns indented">
                <img
                    :src="
                        ASSETS_BASE_URL + 'datamodel/icons/SVG/chan-light.svg'
                    "
                    alt="BossDB Channel Icon"
                    style="height: 1.7em; padding-right: 10px"
                />
                <div>Channels</div>
            </div>
            <ul class="menu-list">
                <li v-for="channel in experiment.channels.slice(0,experiment.displayRange)">
                    {{ channel.name }}
                </li>
                <template v-if="experiment.channels.length > experiment.displayRange + 1">
                    <li>
                        <a @click="experiment.displayRange += maxItems">...</a>
                    </li>
                    <li>
                        {{ experiment.channels.slice(-1)[0].name }}
                    </li>
                </template>
            </ul>
        </div>
    </span>
</template>

<script>
import { ASSETS_BASE_URL } from "@/assets.js";

export default {
    name: "ExperimentDropdown",
    props: {
        exp: Object
    },

    created() {
        this.experiment = {
            id: this.exp.id,
            name: this.exp.name,
            channels: this.exp.channels,
            show: this.exp.show,
            displayRange: this.exp.displayRange
        }
    },
    data() {
        return {
            ASSETS_BASE_URL,
            experiment: this.experiment
        }
    }
}
</script>
